<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                title="Create Investigation Bill"
                @onClickCloseButton="goToList"
            />
        </div>

        <div class="my-2 px-2">
            <TableHeaderData 
                :leftSideData="leftSideData"
                :rightSideData="rightSideData"
            />
        </div>

        <div class="col-12 px-2">
            <ListTable 
                :tableItems="tableItems"
                @addNewItem="addNewItem"
            />
        </div>

    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton';
    import ListTable from '@/components/molecule/company/hospital/investigation/CreateIpdBillingTable.vue';
    import TableHeaderData from '@/components/atom/TableHeaderData.vue';
    import { reactive } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { generateUUID } from '@/services/utils/global';

    const $route = useRoute();
    const $router = useRouter();

    let tableItems = reactive([
        {
            id: generateUUID(),
            name: 'Paracetamol',
            desc: 'Some text will go here',
            qty: 20,
            rate: 230,
            discount: 10,
            vat: 15,
            sub_total: 0,
            discount_amount: 0,
            vat_amout: 0,
            total: 0,
            isEdit: false,
        },
        {
            id: generateUUID(),
            name: 'Calsium-d',
            desc: 'Some text will go here',
            qty: 15,
            rate: 150,
            discount: 10,
            vat: 15,
            sub_total: 0,
            discount_amount: 0,
            vat_amout: 0,
            total: 0,
            isEdit: false,
        },
    ]);

    const leftSideData = reactive([
        {
            key: 'Patient ID',
            value: 'PI-4554'
        },
        {
            key: 'Patient Name',
            value: 'Nazmul Hasan'
        },
        {
            key: 'Gender & Age',
            value: 'Male | 25 years'
        },
        {
            key: 'Patient Phone No',
            value: '+8801922060335'
        },
        {
            key: 'Patient Address',
            value: `8/B, H:31, Eastern Housing Basherpul, Demra, Dhaka`
        }
    ])

    const rightSideData = reactive([
        { 
            key: 'Invoice Date', 
            value: '12-Dec-2022' 
        },
        { 
            key: 'Invoice No', 
            value: '#4848484' 
        },
        { 
            key: 'Admission No', 
            value: '550' 
        },
        { 
            key: 'Place', 
            value: 'Word' 
        },
        { 
            key: 'Consultant', 
            value: 'Dr. Kamruzzaman' 
        },
        { 
            key: 'Entry by', 
            value: 'Nurse' 
        }
    ])

    const addNewItem = () => {

        tableItems.push({
            id: generateUUID(),
            name: '',
            desc: '',
            qty: 0,
            rate: 0,
            discount: 0,
            vat: 0,
            sub_total: 0,
            discount_amount: 0,
            vat_amout: 0,
            total: 0,
            isEdit: false,
        });

    }

    const goToList = () => {
        $router.push({name: 'pharmacy-billing',
            params: {
                companyId: $route.params.companyId,
                moduleId: $route.params.moduleId,
                menuId: $route.params.menuId,
                pageId: $route.params.pageId
            },
            query: {}

        })
    }

</script>

<style>
.tr-box-shadow{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.width-10{
    width: 10%;
}
</style>
<style scoped>
address{
    margin-bottom: 0 !important;
}
</style>